import React, { useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { FormModal } from "./components/FormModal";
import { RawHtmlComponent } from "./components/RawHtmlComponent";
import { Form } from "./Form";
import { createExpressTermQuoteService } from "./services/TransferExpressTermQuoteService";
import { createZipInfoService } from "./services/ZipInfoService";
import { panelTransitionHandler, presentQuote } from "./utils/quoter-helpers";
import {
    yesNoToBooleanOrUndefined as yesNoOrUndefined,
    stringToIntegerOrUndefined as integerOrUndefined,
} from "./utils/value-translators";
import { setCookie, getCookie } from "../../agentQuoter/clubUtils";
import { getClubAndCampaignProfile } from "../../utils/aaalife-dynamic-phone";
import { retrieveUrlParameters } from "../../utils/page";
import { updateDynamicDomElements } from "../../utils/dynamic-dom";
import { AggregationClient } from "./utils/aggregation-client";

// function getCookie(cookieName) {
//     var b = document.cookie.match("(^|;)\\s*" + cookieName + "\\s*=\\s*([^;]+)");
//     return b ? b.pop() : "";
// }

function getLeadTrackingDataFromPage(params, form) {
    return {
        cmp: params.cmp || getCookie("cmp"),
        lead: params.lead || getCookie("lead"),
        source: form?.getAttribute("data-source-prime"),
    };
}

function createUrlWithOptimizelyParameters(pathname, currentUrlString) {
    const currentUrl = new URL(currentUrlString);
    const newUrl = new URL(pathname, currentUrl);
    Array.from(currentUrl.searchParams.entries())
        .filter((param) => /^optimizely_/i.test(param[0]))
        .forEach((param) => newUrl.searchParams.append(param[0], param[1]));
    return newUrl;
}

export function renderForHomepage(window, validationData) {
    // Force the selected product type to be traditional_term so that is
    // preselected.
    window.aaalife.quote = Object.assign(aaalife.data.quote || {}, { type: "traditional_term" });

    renderForNonCampaignPage(
        window.document.querySelector(".panel-container"),
        window.aaalife,
        validationData,
        window.$,
        function (event) {},
        ["traditional_term"],
        "siq",
    );
}

export function renderForTraditionalTermProductPage(window, validationData) {
    renderForNonCampaignPage(
        window.document.querySelector(".panel-container"),
        window.aaalife,
        validationData,
        window.$,
        function (event) {},
        ["traditional_term"],
        "ttq",
    );
}

export function renderForExpressTermProductPage(window, validationData) {
    renderForNonCampaignPage(
        window.document.querySelector(".panel-container"),
        window.aaalife,
        validationData,
        window.$,
        function (event) {},
        ["express_term"],
        "ttq",
    );
}

function renderForNonCampaignPage(element, aaalife, validationData, $, pushToDataLayer, productTypeOptions, initialForm) {
    const resources = {
        tooltipOpenIconUrl: aaalife.fromServer.urlStrings.tooltipOpenIconUrl,
        tooltipCloseIconUrl: aaalife.fromServer.urlStrings.tooltipCloseIconUrl,
        leftArrowSvgIconUrl: aaalife.fromServer.urlStrings.leftArrowSvgIconUrl,
        downArrowSvgIconUrl: aaalife.fromServer.urlStrings.downArrowSvgIconUrl,
        checkmarkSvgIconUrl: aaalife.fromServer.urlStrings.checkmarkSvgIconUrl,
    };

    const params = retrieveUrlParameters();

    const formElement = document.querySelector("#quoteForm");
    const leadTrackingData = getLeadTrackingDataFromPage(params, formElement);

    const formModalElement = window.document.createElement("div");
    const formParent = formElement.parentNode;
    formParent.appendChild(formModalElement);

    const errorModalsData = aaalife.fromServer.errorModalsData;

    function NonCampaignKnockout({ knockoutCode, zip, open, onCloseButtonClicked }) {
        const [clubAndCampaignProfile, setClubAndCampaignProfile] = useState(null);
        useEffect(() => {
            // Note that the below call to getClubAndCampaignProfile without providing a clubCode value is likely
            // an unwanted behavior in that without a zip code provided the clubcode is then after disregarded. See
            // also
            getClubAndCampaignProfile(zip, leadTrackingData.cmp, leadTrackingData.lead)
                .then(setClubAndCampaignProfile)
                .catch((error) => console.error(new Error("Error updating phone", { cause: error })));
        }, [zip]);

        const onUpdateDynamicDomElements = useCallback(
            (rootElement) => {
                if (clubAndCampaignProfile) {
                    updateDynamicDomElements(clubAndCampaignProfile, rootElement);
                }
            },
            [clubAndCampaignProfile],
        );

        return (
            <FormModal
                portalContainer={formModalElement}
                errorHeading={errorModalsData[knockoutCode]?.heading}
                errorDescription={errorModalsData[knockoutCode]?.desc}
                errorSecondaryDescriptionComponent={
                    clubAndCampaignProfile && (
                        <RawHtmlComponent
                            as="p"
                            html={errorModalsData[knockoutCode]?.desc2}
                            attributes={{ className: "error-secondarydesc" }}
                            onDomRootAvailable={(rootElement) => onUpdateDynamicDomElements(rootElement)}
                        />
                    )
                }
                closeIconUrl={resources.tooltipCloseIconUrl}
                open={open}
                onCloseButtonClicked={onCloseButtonClicked}
            />
        );
    }

    sharedRender(
        element,
        aaalife,
        validationData,
        $,
        pushToDataLayer,
        productTypeOptions,
        "/quote-results",
        initialForm,
        resources,
        leadTrackingData,
        params,
        NonCampaignKnockout,
    );
}

export function sharedRender(
    element,
    aaalife,
    validationData,
    $,
    pushToDataLayer,
    productTypeOptions = ["express_term", "traditional_term"],
    quoteResultsPath,
    initialForm,
    resources,
    leadTrackingData,
    params,
    Knockout,
) {
    // HACK: Workaround how jQuery sets values on input elements
    (function ($) {
        var originalVal = $.fn.val;
        $.fn.val = function (value) {
            if (arguments.length >= 1) {
                if (this.selector === "#zip") {
                    this.each(function (index, input) {
                        var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                            window.HTMLInputElement.prototype,
                            "value",
                        ).set;
                        nativeInputValueSetter.call(input, value);

                        var inputEvent = new Event("input", { bubbles: true });
                        input.dispatchEvent(inputEvent);
                    });
                    return this;
                }
            }
            return originalVal.apply(this, arguments);
        };
    })($);

    function triggerZipChanged(zip) {
        var user = aaalife.data.user;
        if (user.zip !== zip) {
            user.zip = zip;
            window.sessionStorage.setItem("user", JSON.stringify(user));
            $(document).trigger("userZipChanged");
        }
    }

    // HACK: Temporary scaffolding for using JQuery based modal for new panels
    const template = document.getElementById("modal-template") || document.createElement("div");
    template.id = "modal-template";
    template.innerHTML = `<a id="content-trigger" data-modal="+ .template-inner-content"></a><div class="hide template-inner-content"  id="inner-content"><span>${new Date().getTime()}
        </span></div>`;
    window.document.body.appendChild(template);
    const triggerElement = $(template.firstChild);
    const contentElement = $(template.lastChild);
    const modal = initModal(aaalife.utils, triggerElement, contentElement);

    // openModal(document.querySelector("#traditionalToolTip").firstElementChild);

    function initModal(utilsToExtend, $triggerElement, $contentElement) {
        return new utilsToExtend.Modal({
            content: $contentElement,
            className: null,
            styles: null,
            triggerEl: $triggerElement,
        });
    }

    function openModal(dataModalElement, modalPopupContent) {
        const contentElementNode = contentElement[0];

        ReactDOM.render(modalPopupContent, contentElementNode);

        modal.open();
    }

    validationData
        .then((validation) => {
            if (!validation?.restrictions) {
                console.error("Expected validation restrictions data was not found.");
            }

            render(element, productTypeOptions, quoteResultsPath, initialForm, {
                aaalife: aaalife,
                validation: validation,
                pushToDataLayer: function (event) {
                    window.dataLayer.push(event);
                },
                triggerZipChanged: triggerZipChanged,
                openModal: openModal,
                resources,
                leadTrackingData,
                params,
                Knockout,
            });
        })
        .catch((error) => {
            console.error("An error occurred retrieving validation restrictions data.", error);
        });
}

/**
 * @typedef {Object} PrefillData - The type describing the state passed to the Form component to prefill user provided values
 * @property {number} zip - The customer's zip
 * @property {"Male"|"Female"} gender - The customer's gender
 * @property {number} dobYear - The customer's birth year
 * @property {number} dobMonth - The customer's birth month
 * @property {number} dobDay - The customer's birth day
 * @property {string} email - The customer's email
 * @property {boolean} isMember - The customer's isMember
 * @property {"express_term"|"traditional_term"} productType - The selected product type
 * @property {boolean} replacingPolicy - The customer's replacingPolicy
 * @property {number} feet - The customer's height in whole feet
 * @property {number} inches - The customer's inches
 * @property {number} weight - The customer's weight
 * @property {string} nicotine - The customer's nicotine
 * @property {string} healthRating - The customer's healthRating
 * @property {string} coverageAmount - The customer's coverageAmount
 * @property {string} termLength - The customer's termLength
 * @property {string} firstName - The customer's firstName
 * @property {string} lastName - The customer's lastName
 * @property {string} phone - The customer's phone
 */

export function render(
    containerElement,
    productTypeOptions,
    quoteResultsPath,
    initialForm,
    { aaalife, validation, pushToDataLayer, triggerZipChanged, openModal, resources, leadTrackingData, params, Knockout },
) {
    const user = aaalife?.data?.user || {};
    const quote = aaalife?.data?.quote || {};

    /** @type {PrefillData} */
    const prefillData = {
        zip: user.zip || undefined,
        dobYear: integerOrUndefined(user.dobYear),
        dobMonth: integerOrUndefined(user.dobMonth),
        dobDay: integerOrUndefined(user.dobDay),
        gender: user.gender || undefined,
        email: user.email || undefined,
        isMember: yesNoOrUndefined(user.isMember),

        productType: quote.type || undefined,
        termLength: quote.term || undefined,
        coverageAmount: quote.coverageAmount || undefined,

        replacingPolicy: yesNoOrUndefined(user.hasPolicy),
        feet: integerOrUndefined(user.heightFeet),
        inches: integerOrUndefined(user.heightInches),
        weight: integerOrUndefined(user.weight),
        nicotine: user.nicotineUsageKey || undefined,
        // "source": "",
        healthRating: user.health || undefined,
        firstName: user.firstName || undefined,
        lastName: user.lastName || undefined,
        phone: user.phone || undefined,
    };

    // DEBUG: Determine if value is a valid property here and if not then adjust/fix
    const aaaLifeReturnPage = document.querySelector("#quoteForm input[name=aaaLifeReturnPage]")?.value;

    const persistGlobalValues = ({ user, quote, validation }) => {
        // Check previous email value first and persist new email source accordingly only if it has changed
        if (aaalife.data.user.email != user.email) {
            setCookie("email_source", user.source, null, true);
        }

        // HACK: Writes to global objects for compatibility
        Object.assign(aaalife.data.user, user);
        Object.assign(aaalife.data.quote, quote);
        Object.assign(aaalife.data.validation, validation);

        sessionStorage.setItem("user", JSON.stringify(aaalife.data.user));
        sessionStorage.setItem("quote", JSON.stringify(aaalife.data.quote));
        sessionStorage.setItem("validation", JSON.stringify(aaalife.data.validation));
    };

    const healthConditionGroups = validation.healthConditions.values.map((c) => ({
        maxAge: parseInt(c.criteria.maxAge, 10),
        minBmi: parseFloat(c.criteria.minBMI),
        maxBmi: parseFloat(c.criteria.maxBMI),
        nicotineUsedLast12Months: c.criteria.nicotineUsedLast12Months === "true",
        healthRatings: c.allowableHealthConditions,
    }));

    const isValidBmi = (age, bmi) => {
        return healthConditionGroups.find(
            (condition) => age <= condition.maxAge && bmi >= condition.minBmi && bmi <= condition.maxBmi,
        );
    };

    const getHealthRatings = (age, bmi, nicotineUser) => {
        return healthConditionGroups.find(
            (condition) =>
                age <= condition.maxAge &&
                bmi >= condition.minBmi &&
                bmi <= condition.maxBmi &&
                nicotineUser === condition.nicotineUsedLast12Months,
        );
    };

    const zipInfoService = createZipInfoService(validation.restrictions);
    const zipInfoLookupEffect = zipInfoService.getZipInfo;

    const restrictions = {
        invalidStates: validation.restrictions.allTermNotOffered,
        validAgeRange: {
            minAge: validation.restrictions.ageRestrictions.minAge,
            maxAge: validation.restrictions.ageRestrictions.maxAge,
        },
        isValidBmi,
        getHealthRatings,
    };

    const transferExpressTermQuoteService = createExpressTermQuoteService({
        etSubdomain: aaalife.fromServer.urlStrings.etSubdomain,
        transferedQuoteApiUrl: aaalife.fromServer.urlStrings.etQuoteTransferApiUrl,
    });

    const aggregationClient = new AggregationClient({
        panelOne: aaalife.fromServer.quoteFormParameters.panelOneInteractionTypeCode,
        panelTwo: aaalife.fromServer.quoteFormParameters.panelTwoInteractionTypeCode,
        panelThree: aaalife.fromServer.quoteFormParameters.panelThreeInteractionTypeCode,
        panelFour: aaalife.fromServer.quoteFormParameters.panelFourInteractionTypeCode,
        quoteResults: aaalife.fromServer.quoteFormParameters.quoteButtonText,
    });

    const presentQuoteHandler = (state) => {
        getClubAndCampaignProfile(state.zip, leadTrackingData.cmp, leadTrackingData.lead)
            .then((clubProfileData) => {
                // HACK: Relies on lookup happening in other parts of the code and storing this value away.
                const leadRouting = clubProfileData?.routingType || "DSU";

                // HACK: Preserve optimizely query string parameters in order to force variation in automated tests
                const quoteResultsPageUrl = createUrlWithOptimizelyParameters(quoteResultsPath, window.location.href);
                quoteResultsPageUrl.searchParams.append("initial_form", initialForm);

                presentQuote(
                    state,
                    persistGlobalValues,
                    transferExpressTermQuoteService,
                    (state) =>
                        aggregationClient.submitQuoteRequest(state, {
                            source: leadTrackingData.source,
                            aaaLifeReturnPage,
                            leadRouting,
                        }),
                    leadTrackingData.source,
                    quoteResultsPageUrl,
                );
            })
            .catch((error) => {
                console.error(
                    new Error("Unable to retrieve lead routing information so quote will not be presented to the user.", {
                        cause: error,
                    }),
                );
            });
    };

    const panelTransitionHandlerWrapper = (state) => {
        panelTransitionHandler(state, persistGlobalValues, pushToDataLayer, leadTrackingData.source, aggregationClient);
    };

    ReactDOM.render(
        <Form
            prefillData={prefillData}
            restrictions={restrictions}
            effects={{
                panelTransitionHandler: panelTransitionHandlerWrapper,
                presentQuoteHandler,
                zipInfoLookupEffect,
                triggerZipChanged,
                openModalEffect: openModal,
            }}
            resources={resources}
            productTypeOptions={productTypeOptions}
            Knockout={Knockout}
            disclaimersHtml={aaalife.fromServer.quoteFormParameters.disclaimersHtml}
        />,
        containerElement,
    );
}
