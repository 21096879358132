import React, { useReducer, useEffect } from "react";
import { useReactivateEffect } from "../hooks/ReactivateEffect";
import { createValidationError } from "../utils/form-state";
import { isEmailAddressFormatValid } from "../../../utils/email-format-validation";
import { isPhoneFormatValid } from "../../../utils/phone-format-validation";
import { format10DigitNanpaPhone, parseUnformatted10DigitPhone } from "../../../utils/phone";
import { validateProperty } from "../../../utils/property-validation";
import {
    FIRST_NAME_INVALID_CHARACTERS_EXPRESSION,
    LAST_NAME_INVALID_CHARACTERS_EXPRESSION,
} from "../../../utils/form-field-constants";

const FIELDS = {
    FIRST_NAME: "firstName",
    LAST_NAME: "lastName",
    EMAIL: "email",
    PHONE: "phone",
};

/**
 * @typedef {"firstName"|"lastName"|"email"|"phone"} Panel4FieldNames - All possible field names for panel 4
 */

const CHANGED_FIELD_VALUE = "CHANGED_FIELD_VALUE";
const NAVIGATE_NEXT = "NAVIGATE_NEXT";
const ACTIVATED = "ACTIVATED";

const formValidationRules = {
    firstName: {
        required: true,
        type: "string",
        minLength: 1,
        maxLength: 20,
        regex: /^([a-zA-Z0-9'.\-])+$/,
        validFormatDescription:
            "a name containing at least one and no more than twenty characters each of which are alpha numeric, apostrophe, period, or a dash.",
    },
    lastName: {
        required: true,
        type: "string",
        minLength: 1,
        maxLength: 30,
        regex: /^([a-zA-Z0-9'.\-\s])+$/,
        validFormatDescription:
            "a name containing at least one and no more than twenty characters each of which are alpha numeric, apostrophe, period, dash, or a space.",
    },
};

/**
 * @param {Panel4FieldNames} name
 * @param {string} value
 */
const validateFirstName = (name, value) => {
    const errorMessage = validateProperty(formValidationRules[name], name, value);
    return errorMessage ? { fields: [name], message: errorMessage.errorMessage } : null;
};

/**
 * @param {Panel4FieldNames} name
 * @param {string} value
 */
const validateLastName = (name, value) => {
    const errorMessage = validateProperty(formValidationRules[name], name, value);
    return errorMessage ? { fields: [name], message: errorMessage.errorMessage } : null;
};

function isValid(fieldName, fieldErrors) {
    return !fieldErrors.some((error) => error.fields.some((field) => field === fieldName));
}

/**
 * @typedef {Object} Panel4Fields - Specific fields used by panel 4
 * @property {string} firstName - The customer's first name if provided, otherwise `undefined`
 * @property {string} lastName - The customer's last name if provided, otherwise `undefined`
 * @property {string} email - The customer's email address if provided, otherwise `undefined`
 * @property {string} phone - The customer's phone number if provided, otherwise `undefined`
 */

/**
 * @typedef {Object} Panel4State - The internal state used for panel
 * @property {Panel4Fields} fields - Field values entered by the user
 * @property {Array.<import("../utils/form-state").ValidationError>} validationErrors - The validation errors if any
 * @property {boolean} shouldNavigateNext - Indicates whether the panel is in a state where navigation should happen to the next panel
 */

/**
 * @param {Panel4State} state - The current state of the reducer
 * @param {Object} action - The action that has been dispatched to the reducer
 */
const stateReducer = (state, action) => {
    switch (action.type) {
        case ACTIVATED:
            return action.state;
        case CHANGED_FIELD_VALUE:
            return {
                ...state,
                fields: { ...state.fields, [action.fieldName]: action.value },
                validationErrors: state.validationErrors.filter((error) => !error.fields.includes(action.fieldName)),
            };
        case NAVIGATE_NEXT:
            const newValidationErrors = [
                validateFirstName("firstName", state.fields.firstName),
                validateLastName("lastName", state.fields.lastName),
                !isPhoneFormatValid(state.fields.phone) &&
                    createValidationError({
                        fields: [FIELDS.PHONE],
                        message: `Expected ${FIELDS.PHONE} to be formatted as a valid phone number.`,
                    }),
                !isEmailAddressFormatValid(state.fields.email) &&
                    createValidationError({
                        fields: [FIELDS.EMAIL],
                        message: `Expected ${FIELDS.EMAIL} to be formatted as a valid email address.`,
                    }),
            ].filter((error) => error);

            return {
                ...state,
                shouldNavigateNext: newValidationErrors.length === 0,
                validationErrors: newValidationErrors,
            };

        default:
            return state;
    }
};

/**
 * @typedef {Object} Panel4Props - A validation error
 * @property {boolean} active - A value that is `true` if the panel is active otherwise `false`
 * @property {function(Panel4Fields) : void} onNext - Callback function used when panel is ready to signal to navigate next
 * @property {function(Panel4Fields) : void} onBack - Callback function used when panel is ready to signal to navigate back
 * @property {Panel4Fields} initialState - The initial state to be used by the component when initializing or reactating
 * @property {String} disclaimersHtml - The raw HTML for FCC disclaimers text
 * @property {{leftArrowSvgIconUrl}} resources - An object specifying content resources used to render
 */

/**
 * @param {Panel4Props} props - The properties passed ot the Panel4 component
 */
export function Panel4({ active, onNext, onBack, initialState, disclaimersHtml, resources: { leftArrowSvgIconUrl } }) {
    /** @type {function() : Panel4State} */
    const createInitialState = () => ({
        shouldNavigateNext: false,
        validationErrors: [],
        fields: {
            firstName: initialState.firstName || "",
            lastName: initialState.lastName || "",
            phone: initialState.phone || "",
            email: initialState.email || "",
        },
    });
    /** @type {[Panel4State, function(object) : void]} */
    const [state, dispatch] = useReducer(stateReducer, {}, createInitialState);
    useReactivateEffect(() => dispatch({ type: ACTIVATED, state: createInitialState() }), active);

    useEffect(() => {
        if (state.shouldNavigateNext) {
            onNext({ ...state.fields });
        }
    }, [state.shouldNavigateNext]);

    /**
     * @param {Panel4FieldNames} fieldName
     */
    const validOrInvalid = (fieldName) => (isValid(fieldName, state.validationErrors) ? "valid" : "invalid");

    /**
     * @param {Panel4FieldNames} fieldName
     */
    const pristineOrDirty = (fieldName) => (state.fields[fieldName] !== undefined ? "dirty" : "pristine");

    return (
        <div
            className={["panel", "panel-panel4", active ? "active" : "hide"].join(" ")}
            data-event="TQSP3"
            style={{ width: 390, left: 0, paddingRight: "0px", marginRight: "0px" }}
        >
            <p
                className={`inlineValidation inlineValidation-quote ${state.validationErrors.length > 0 ? "show" : ""}`}
                style={{
                    width: "100%",
                    minHeight: "18px",
                    paddingLeft:
                        "0px" /*HACK: Set explicitly to mimic what happens for others specific subsequent panels with lower numbers styled in CSS. */,
                }}
            >
                Please complete/correct the required fields
            </p>
            <input type="hidden" id="attr-specialAction" name="attr-specialAction" defaultValue="sf-p" className="pristine" />
            <div className="globalForm">
                {state.validationErrors.length == 0 && (
                    <div className="row">
                        <div className="small-12 large-12 columns innerContent" style={{ minHeight: "28px" }}>
                            <p>One last step to get your free quote!</p>
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="small-12 large-12 columns">
                        <label htmlFor="firstName" className="globalForm-labels">
                            First Name
                        </label>
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            placeholder=""
                            maxLength={20}
                            autoComplete="given-name"
                            className={[validOrInvalid("firstName"), pristineOrDirty("firstName")].join(" ")}
                            value={state.fields.firstName}
                            onChange={(e) =>
                                dispatch({
                                    type: CHANGED_FIELD_VALUE,
                                    fieldName: FIELDS.FIRST_NAME,
                                    value: e.target.value.replace(FIRST_NAME_INVALID_CHARACTERS_EXPRESSION, ""),
                                })
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="small-12 large-12 columns">
                        <label htmlFor="lastName" className="globalForm-labels">
                            Last Name
                        </label>
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            placeholder=""
                            maxLength={30}
                            autoComplete="family-name"
                            className={[validOrInvalid("lastName"), pristineOrDirty("lastName")].join(" ")}
                            value={state.fields.lastName}
                            onChange={(e) =>
                                dispatch({
                                    type: CHANGED_FIELD_VALUE,
                                    fieldName: FIELDS.LAST_NAME,
                                    value: e.target.value.replace(LAST_NAME_INVALID_CHARACTERS_EXPRESSION, ""),
                                })
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="small-12 large-12 columns">
                        <label htmlFor="phone" className="globalForm-labels">
                            Phone
                        </label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            placeholder=""
                            maxLength={17}
                            autoComplete="tel-national"
                            className={[validOrInvalid("phone"), pristineOrDirty("phone")].join(" ")}
                            value={format10DigitNanpaPhone(state.fields.phone)}
                            onChange={(e) =>
                                dispatch({
                                    type: CHANGED_FIELD_VALUE,
                                    fieldName: FIELDS.PHONE,
                                    value: parseUnformatted10DigitPhone(e.target.value),
                                })
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="small-12 large-12 columns">
                        <label htmlFor="required_email" className="globalForm-labels">
                            Email
                        </label>
                        <input
                            type="email"
                            id="required_email"
                            name="email"
                            placeholder="you@email.com"
                            maxLength={100}
                            autoComplete="email"
                            className={[validOrInvalid("email"), pristineOrDirty("email")].join(" ")}
                            value={state.fields.email}
                            onChange={(e) =>
                                dispatch({ type: CHANGED_FIELD_VALUE, fieldName: FIELDS.EMAIL, value: e.target.value })
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div
                        className="small-12 large-12 columns applyNow-disclaimers-text disclaimersContainer"
                        dangerouslySetInnerHTML={{ __html: disclaimersHtml }}
                    ></div>
                </div>
            </div>
            <div className="buttonWrap" style={{ marginBottom: "18px" }}>
                <button
                    type="button"
                    id="panel4-back"
                    className="button panel-container-backbtn"
                    style={{ paddingRight: 0 }}
                    onClick={(e) => onBack(state.fields)}
                >
                    Back
                    <svg className="icon panel-container-icon-arrow-left">
                        <use xlinkHref={leftArrowSvgIconUrl} />
                    </svg>
                </button>
                <button
                    id="seeQuote"
                    type="button"
                    data-itc
                    className="button btn-pink panel-container-seeMyQuote "
                    onClick={(e) => dispatch({ type: NAVIGATE_NEXT })}
                >
                    See Your Quote
                </button>
            </div>
        </div>
    );
}
