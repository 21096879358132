import React, { useReducer, useEffect, useState } from "react";
import { Tooltip } from "../components/Tooltip";
import { useReactivateEffect } from "../hooks/ReactivateEffect";
import { ageLastBirthday, createDate } from "../utils/age";
import { PRODUCT_TYPES } from "../constants/PRODUCT_TYPES";
import { TraditionalTermHealthConditionsPopup } from "../components/TraditionalTermHealthConditionsPopup";
import { createValidationError } from "../utils/form-state";

const FIELDS = {
    REPLACING_POLICY: "replacingPolicy",
    HEIGHT_FEET: "feet",
    HEIGHT_INCHES: "inches",
    WEIGHT: "weight",
    NICOTINE_USE: "nicotine",
    HEALTH_RATING: "healthRating",
    COVERAGE_AMOUNT: "coverageAmount",
    TERM_LENGTH: "termLength",
};

const KNOCKOUT_CODES = {
    EXISTING_POLICY: "existingPolicy",
};

const CHANGED_FIELD_VALUE = "CHANGED_FIELD_VALUE";
const NAVIGATE_NEXT = "NAVIGATE_NEXT";
const CUSTOMER_AGED = "CUSTOMER_AGED";
const ACTIVATED = "ACTIVATED";

var HEALTH_RATING_TEXT_CONTENTS = {
    excellent: "Excellent",
    verygood: "Very Good",
    good: "Good",
    fair: "Fair",
};

const MAX_AGE_FOR_THIRTY_YEAR_TERM_FOR_NICOTINE_USER = 45;

function isValid(fieldName, fieldErrors) {
    return !fieldErrors.some((error) => error.fields.some((field) => field === fieldName));
}

var expressTermCoverageAmounts = [
    "25,000",
    "50,000",
    "75,000",
    "100,000",
    "125,000",
    "150,000",
    "175,000",
    "200,000",
    "225,000",
    "250,000",
    "300,000",
    "350,000",
    "400,000",
    "450,000",
    "500,000",
];

const traditionalTermCoverageAmounts = [
    "50,000",
    "100,000",
    "150,000",
    "200,000",
    "250,000",
    "300,000",
    "350,000",
    "400,000",
    "450,000",
    "500,000",
    "550,000",
    "600,000",
    "650,000",
    "700,000",
    "750,000",
    "800,000",
    "850,000",
    "900,000",
    "950,000",
    "1,000,000",
    "2,000,000",
];

/**
 * @typedef {("nicotineUse-Never"
 *  |"nicotineUse-MoreThan60MonthsAgo"
 *  |"nicotineUse-WithinPast36To59Months"
 *  |"nicotineUse-WithinPast12To35Months"
 *  |"nicotineUse-LessThan12MonthsAgo")} NicotineUseNames - A validation error
 */

/**
 * @typedef {Object} TermLengthOption - A validation error
 * @property {number} minAge
 * @property {number} maxAge
 * @property {Array.<number>} termLengths
 */

const traditionalTermLengthAgeGroups = [
    { minAge: 0, maxAge: 55, termLengths: [10, 15, 20, 30] },
    { minAge: 55, maxAge: 65, termLengths: [10, 15, 20] },
    { minAge: 65, maxAge: 70, termLengths: [10, 15] },
    { minAge: 70, maxAge: 125, termLengths: [10] },
];

const expressTermLengthAgeGroups = [
    { minAge: 18, maxAge: 55, termLengths: [10, 15, 20, 30] },
    { minAge: 55, maxAge: 65, termLengths: [10, 15, 20] },
    { minAge: 65, maxAge: 70, termLengths: [10, 15] },
    { minAge: 70, maxAge: 75, termLengths: [10] },
];

/**
 * @param {number} age
 * @param {ProductTypes} productType
 * @param {boolean} nicotineUser
 * @returns {Array.<number>}
 */
const getTermLengthOptions = (age, productType, nicotineUser) => {
    const termLengthAgeGroups =
        productType === PRODUCT_TYPES.TRADITIONAL_TERM
            ? traditionalTermLengthAgeGroups
            : productType === PRODUCT_TYPES.EXPRESS_TERM
              ? expressTermLengthAgeGroups
              : [];
    const excludeThirtyYearTerm = nicotineUser && age > MAX_AGE_FOR_THIRTY_YEAR_TERM_FOR_NICOTINE_USER;

    return (
        termLengthAgeGroups
            .find((ageGroup) => age >= ageGroup.minAge && age <= ageGroup.maxAge)
            ?.termLengths?.filter((termLength) => !(termLength === 30 && excludeThirtyYearTerm)) || []
    );
};

const getCoverageAmounts = (productType) => {
    return productType === PRODUCT_TYPES.TRADITIONAL_TERM
        ? traditionalTermCoverageAmounts
        : productType === PRODUCT_TYPES.EXPRESS_TERM
          ? expressTermCoverageAmounts
          : [];
};

function toHeightInInches(feet, inches) {
    return feet * 12 + inches;
}

function toBmi(inches, pounds) {
    return (pounds / inches ** 2) * 703;
}

/**
 * @param {NicotineUseNames} nicotineUsage
 * @returns {boolean}
 */
const usedNicotineRecently = (nicotineUsage) => {
    return nicotineUsage === "nicotineUse-LessThan12MonthsAgo";
};

const bmiFields = [FIELDS.HEIGHT_FEET, FIELDS.HEIGHT_INCHES, FIELDS.WEIGHT];

// function isBmiField(fieldName) {
//     return bmiFields.indexOf(fieldName) >= 0;
// }

// function areAllBmiFieldsFilled(fields) {
//     return bmiFields.map(fieldName => fields[fieldName]).every(value => value);
// }

function bmiFieldChanged(fieldName, value) {
    return { type: CHANGED_FIELD_VALUE, fieldName, value, now: new Date().getTime() };
}

// function getHealthRatingsForProduct(healthRatings, productType, bmi, nicotineUser) {
// }

/**
 * @typedef {"traditional_term"|"express_term"} ProductTypes - All allowable product type values
 */

/**
 * @typedef {Object} DateOfBirth - A date of birth value
 * @property {number} year - year
 * @property {number} month - month
 * @property {number} day - day
 */

/**
 * @typedef {Object} Panel3Fields - Specific fields used by panel 4
 * @property {string} replacingPolicy - The customer's replacingPolicy if provided, otherwise `undefined`
 * @property {string} feet - The customer's feet if provided, otherwise `undefined`
 * @property {string} inches - The customer's inches if provided, otherwise `undefined`
 * @property {string} weight - The customer's weight if provided, otherwise `undefined`
 * @property {NicotineUseNames} nicotine - The customer's nicotine if provided, otherwise `undefined`
 * @property {string} healthRating - The customer's healthRating if provided, otherwise `undefined`
 * @property {string} coverageAmount - The customer's coverageAmount if provided, otherwise `undefined`
 * @property {string} termLength - The customer's termLength if provided, otherwise `undefined`
 */

/**
 * @typedef {Object} Panel3State - The internal state used for panel
 * @property {Panel3Fields} fields - Field values entered by the user
 * @property {Array.<import("../utils/form-state").ValidationError>} validationErrors - The validation errors if any
 * @property {boolean} shouldNavigateNext - Indicates whether the panel is in a state where navigation should happen to the next panel
 * @property {ProductTypes} productType - The type of product that he customer has selected
 * @property {number} age - The most recently calculated age based on the DOB entered by the user
 * @property {number} bmi - The most recently calculated BMI value based on other fields entered by the user
 * @property {DateOfBirth} dob - The customer's specified date of birth
 * @property {Object} healthConditionGroup - The health condition group associated to the user's other fields,
 */

/** @type {function(Object, boolean) : (function(Panel3State, Object) : Panel3State)} */
const createReducer = (restrictions, replacementPolicyNotOffered) => {
    return (state, action) => {
        switch (action.type) {
            case ACTIVATED: {
                const state = action.state;
                const updatedFields = state.fields;

                const { bmi, healthConditionGroup, constrainedHealthRating } = calculateFieldConditionalState(
                    updatedFields[FIELDS.HEIGHT_FEET],
                    updatedFields[FIELDS.HEIGHT_INCHES],
                    updatedFields[FIELDS.WEIGHT],
                    updatedFields[FIELDS.NICOTINE_USE],
                    updatedFields[FIELDS.HEALTH_RATING],
                    state.productType,
                    state.age,
                    restrictions.getHealthRatings,
                );

                return {
                    ...state,
                    fields: { ...updatedFields, [FIELDS.HEALTH_RATING]: constrainedHealthRating },
                    bmi,
                    healthConditionGroup,
                };
            }
            case CUSTOMER_AGED: {
                const { bmi, healthConditionGroup, constrainedHealthRating } = calculateFieldConditionalState(
                    state.fields[FIELDS.HEIGHT_FEET],
                    state.fields[FIELDS.HEIGHT_INCHES],
                    state.fields[FIELDS.WEIGHT],
                    state.fields[FIELDS.NICOTINE_USE],
                    state.fields[FIELDS.HEALTH_RATING],
                    state.productType,
                    action.age,
                    restrictions.getHealthRatings,
                );

                return {
                    ...state,
                    age: action.age,
                    fields: { ...state.fields, [FIELDS.HEALTH_RATING]: constrainedHealthRating },
                    bmi,
                    healthConditionGroup,
                };
            }
            case CHANGED_FIELD_VALUE: {
                const updatedFields = { ...state.fields, [action.fieldName]: action.value };

                const { bmi, healthConditionGroup, constrainedHealthRating } = calculateFieldConditionalState(
                    updatedFields[FIELDS.HEIGHT_FEET],
                    updatedFields[FIELDS.HEIGHT_INCHES],
                    updatedFields[FIELDS.WEIGHT],
                    updatedFields[FIELDS.NICOTINE_USE],
                    updatedFields[FIELDS.HEALTH_RATING],
                    state.productType,
                    state.age,
                    restrictions.getHealthRatings,
                );

                return {
                    ...state,
                    fields: { ...updatedFields, [FIELDS.HEALTH_RATING]: constrainedHealthRating },
                    bmi,
                    healthConditionGroup,
                    validationErrors: state.validationErrors.filter(
                        (error) => !error.fields.includes(action.fieldName) && !error.knockout,
                    ),
                };
            }
            case NAVIGATE_NEXT: {
                const requiredFields = Object.keys(FIELDS).filter(
                    (field) =>
                        !(state.productType === PRODUCT_TYPES.TRADITIONAL_TERM && FIELDS[field] === FIELDS.REPLACING_POLICY) &&
                        !(state.productType === PRODUCT_TYPES.EXPRESS_TERM && FIELDS[field] === FIELDS.HEALTH_RATING),
                );
                const newValidationErrors = [
                    ...requiredFields.map((key) =>
                        state.fields[FIELDS[key]] !== undefined
                            ? null
                            : {
                                  fields: [FIELDS[key]],
                                  message: `Expected ${FIELDS[key]} to have a value because it is required.`,
                              },
                    ),
                    replacementPolicyNotOffered && state.fields[FIELDS.REPLACING_POLICY]
                        ? createValidationError({
                              fields: [FIELDS.REPLACING_POLICY],
                              message: `Expected not to be replacing an existing policy with a new ExpressTerm policy.`,
                              knockout: KNOCKOUT_CODES.EXISTING_POLICY,
                          })
                        : null,
                    state.productType !== PRODUCT_TYPES.EXPRESS_TERM &&
                    state.age &&
                    state.bmi &&
                    !restrictions.isValidBmi(state.age, state.bmi)
                        ? {
                              fields: [],
                              message: `Expected BMI to be within range for any of the known health condition groups.`,
                              knockout: "noQuoteAvailable",
                          }
                        : null,
                    !getTermLengthOptions(state.age, state.productType, usedNicotineRecently(state.fields.nicotine)).includes(
                        parseInt(state.fields.termLength, 10),
                    )
                        ? {
                              fields: ["termLength"],
                              message: `Expected termLength to be be one of the allowed term lengths based on age and nicotine usage.`,
                          }
                        : null,
                ].filter((error) => error);

                return {
                    ...state,
                    shouldNavigateNext: newValidationErrors.length === 0,
                    validationErrors: newValidationErrors,
                };
            }
            default:
                return state;
        }
    };
};

function calculateFieldConditionalState(
    heightFeet,
    heightInches,
    weight,
    nicotineUse,
    healthRating,
    productType,
    age,
    getHealthRatings,
) {
    const areAllBmiFieldsFilled = [heightFeet, heightInches, weight].every((value) => typeof value !== "undefined");
    const bmi = areAllBmiFieldsFilled ? toBmi(toHeightInInches(heightFeet, heightInches), weight) : undefined;

    const healthConditionGroup =
        productType === PRODUCT_TYPES.TRADITIONAL_TERM && age && bmi && nicotineUse
            ? getHealthRatings(age, bmi, nicotineUse === "nicotineUse-LessThan12MonthsAgo")
            : undefined;
    const constrainedHealthRating =
        healthConditionGroup !== undefined
            ? healthConditionGroup.healthRatings.indexOf(healthRating) >= 0
                ? healthRating
                : healthConditionGroup.healthRatings.length === 1
                  ? healthConditionGroup.healthRatings[0]
                  : undefined
            : healthRating;
    return { bmi, healthConditionGroup, constrainedHealthRating };
}

/**
 * @typedef {Object} Panel3Props - A validation error
 * @property {boolean} active - A value that is `true` if the panel is active otherwise `false`
 * @property {function(Panel3Fields) : void} onNext - Callback function used when panel is ready to signal to navigate next
 * @property {function(Panel3Fields) : void} onBack - Callback function used when panel is ready to signal to navigate back
 * @property {Panel3Fields} initialState - The initial state to be used by the component when initializing or reactating
 * @property {{tooltipOpenIconUrl, tooltipCloseIconUrl, leftArrowSvgIconUrl, downArrowSvgIconUrl}} resources - An object specifying content resources used to render
 * @property {function} knockoutEffect - knockoutEffect
 * @property {function} openModalEffect - openModalEffect
 * @property {{getHealthRatings: function, isValidBmi: function }} restrictions - restrictions
 * @property {boolean} replacementPolicyNotOffered - replacementPolicyNotOffered
 * @property {string} zip - zip
 * @property {ProductTypes} productType - productType
 * @property {DateOfBirth} dob - dob
 * @property {number} age - age
 */

/**
 * @param {Panel3Props} props - The properties passed ot the Panel4 component
 */
export function Panel3({
    active,
    onNext,
    onBack,
    initialState,
    knockoutEffect,
    openModalEffect,
    restrictions,
    replacementPolicyNotOffered,
    zip,
    productType,
    dob,
    age,
    resources: { tooltipOpenIconUrl, tooltipCloseIconUrl, leftArrowSvgIconUrl, downArrowSvgIconUrl },
}) {
    /** @type {function() : Panel3State} */
    const createInitialState = () => ({
        shouldNavigateNext: false,
        validationErrors: [],
        productType: productType,
        dob: dob,
        age: age,
        bmi: undefined,
        healthConditionGroup: undefined,
        fields: {
            replacingPolicy: initialState.replacingPolicy,
            feet: initialState.feet,
            inches: initialState.inches,
            weight: initialState.weight,
            nicotine: initialState.nicotine,
            healthRating: initialState.healthRating,
            coverageAmount: initialState.coverageAmount || (productType === "traditional_term" ? "500,000" : undefined),
            termLength: initialState.termLength,
        },
    });

    const [state, dispatch] = useReducer(createReducer(restrictions, replacementPolicyNotOffered), {}, createInitialState);
    useReactivateEffect(() => {
        dispatch({
            type: ACTIVATED,
            state: {
                ...createInitialState(),
                // age: state.age,
                // bmi: state.bmi,
                // healthConditionGroup: state.healthConditionGroup,
            },
        });
    }, active);

    useEffect(() => {
        const age = ageLastBirthday(createDate(dob.year, dob.month, dob.day), new Date());
        if (state.age !== age) {
            dispatch({ type: CUSTOMER_AGED, age: age });
        }
    }, [active, dob.year, dob.month, dob.day, age]);

    const knockouts = state.validationErrors.map((error) => error.knockout).filter((knockout) => knockout);
    useEffect(() => {
        if (knockouts.length > 0) {
            knockoutEffect(knockouts[0], zip);
        }
    }, [knockouts.length, knockouts[0]]);
    const replacementPolicyKnockout = state.validationErrors.some((error) => error.knockout === KNOCKOUT_CODES.EXISTING_POLICY);

    useEffect(() => {
        if (state.shouldNavigateNext) {
            onNext({ ...state.fields, healthConditionGroup: state.healthConditionGroup });
        }
    }, [state.shouldNavigateNext]);

    const productTypeClass = productType === "traditional_term" ? "traditional_term" : "express_term";

    const hideRateYourHealth = !state.healthConditionGroup || state.healthConditionGroup.healthRatings.length <= 1;

    const validOrInvalid = (fieldName) => (isValid(fieldName, state.validationErrors) ? "valid" : "invalid");

    const pristineOrDirty = (fieldName) => (state.fields[fieldName] !== undefined ? "dirty" : "pristine");

    const modalPopupContent =
        productType === "express_term" ? null : ( // Unexpected case, health ratings don't show for Express Term any longer
            <TraditionalTermHealthConditionsPopup
                downArrowSvgIconUrl={downArrowSvgIconUrl}
                usedNicotine={usedNicotineRecently(state.fields[FIELDS.NICOTINE_USE])}
                conditions={state?.healthConditionGroup?.healthRatings}
            />
        );
    return (
        <div
            className={["panel", "panel-panel3", active ? "active" : "hide", productTypeClass].join(" ")}
            data-event="TQSP2"
            style={{ width: "390px", left: "0px" }}
        >
            <p className={`inlineValidation inlineValidation-quote ${state.validationErrors.length > 0 ? "show" : ""}`}>
                {" "}
                Please complete/correct the required fields
            </p>
            <input type="hidden" id="attr-specialAction" name="attr-specialAction" defaultValue="sf-p" className="pristine" />
            <div className="globalForm">
                {replacementPolicyNotOffered && (
                    <div className="replaceExistingPolicy row hide-for-traditionalTerm">
                        <fieldset className="small-12 large-12 columns">
                            <legend>Are you replacing an existing policy?</legend>
                            <div className="twoRadioStyle">
                                <input
                                    type="radio"
                                    value="Yes"
                                    name="policy_type"
                                    id="yesExistingPolicy"
                                    className={[
                                        validOrInvalid(FIELDS.REPLACING_POLICY),
                                        pristineOrDirty(FIELDS.REPLACING_POLICY),
                                    ].join(" ")}
                                    checked={state.fields[FIELDS.REPLACING_POLICY] === true}
                                    onChange={(e) =>
                                        dispatch({
                                            type: CHANGED_FIELD_VALUE,
                                            fieldName: FIELDS.REPLACING_POLICY,
                                            value: e.target.value === "Yes",
                                        })
                                    }
                                />
                                <label htmlFor="yesExistingPolicy">
                                    <span>
                                        <span />
                                    </span>
                                    Yes
                                </label>
                                <input
                                    type="radio"
                                    value="No"
                                    name="policy_type"
                                    id="noExistingPolicy"
                                    className={[
                                        replacementPolicyKnockout ? "" : validOrInvalid(FIELDS.REPLACING_POLICY),
                                        pristineOrDirty(FIELDS.REPLACING_POLICY),
                                    ].join(" ")}
                                    checked={state.fields[FIELDS.REPLACING_POLICY] === false}
                                    onChange={(e) =>
                                        dispatch({
                                            type: CHANGED_FIELD_VALUE,
                                            fieldName: FIELDS.REPLACING_POLICY,
                                            value: e.target.value !== "No",
                                        })
                                    }
                                />
                                <label htmlFor="noExistingPolicy">
                                    <span>
                                        <span />
                                    </span>
                                    No
                                </label>
                            </div>
                        </fieldset>
                    </div>
                )}
                <div className="row form-group-inline bmi">
                    <div className="small-4 large-4 columns">
                        <label htmlFor="feet">Height</label>
                        <select
                            name="feet"
                            id="feet"
                            className={[validOrInvalid(FIELDS.HEIGHT_FEET), pristineOrDirty(FIELDS.HEIGHT_FEET)].join(" ")}
                            value={state.fields[FIELDS.HEIGHT_FEET] || ""}
                            onChange={(e) =>
                                dispatch(
                                    bmiFieldChanged(
                                        FIELDS.HEIGHT_FEET,
                                        e.target.value === "" ? undefined : parseInt(e.target.value, 10),
                                    ),
                                )
                            }
                        >
                            <option value="" hidden>
                                FT.
                            </option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                        </select>
                    </div>
                    <div className=" small-4 large-4 columns">
                        <label htmlFor="inches" style={{ visibility: "hidden" }}>
                            Inches
                        </label>
                        <select
                            name="inches"
                            id="inches"
                            className={[
                                "inc",
                                validOrInvalid(FIELDS.HEIGHT_INCHES),
                                pristineOrDirty(FIELDS.HEIGHT_INCHES),
                            ].join(" ")}
                            value={typeof state.fields.inches === "number" ? state.fields.inches : ""}
                            onChange={(e) =>
                                dispatch(
                                    bmiFieldChanged(
                                        FIELDS.HEIGHT_INCHES,
                                        e.target.value === "" ? undefined : parseInt(e.target.value, 10),
                                    ),
                                )
                            }
                        >
                            <option value="" hidden>
                                IN.
                            </option>
                            <option value={0}>0</option>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                            <option value={8}>8</option>
                            <option value={9}>9</option>
                            <option value={10}>10</option>
                            <option value={11}>11</option>
                        </select>
                    </div>
                    <div className=" small-4 large-4 columns">
                        <label htmlFor="weight">Weight</label>
                        <input
                            id="weight"
                            name="weight"
                            type="tel"
                            maxLength={3}
                            className={["weightNumeric", validOrInvalid(FIELDS.WEIGHT), pristineOrDirty(FIELDS.WEIGHT)].join(
                                " ",
                            )}
                            placeholder="LBS."
                            value={state.fields[FIELDS.WEIGHT] || ""}
                            onChange={(e) =>
                                dispatch(
                                    bmiFieldChanged(
                                        FIELDS.WEIGHT,
                                        (e.target.value || undefined) && parseInt(e.target.value, 10),
                                    ),
                                )
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <fieldset className="small-12 large-12 columns">
                        <legend>
                            When was the last time you used any{" "}
                            <span style={{ display: "inline-block" }}>type of nicotine product?</span>{" "}
                            <Tooltip
                                id="nicotineToolTip"
                                svgClass="quoteForm-icon-arrow-questionMark"
                                divClass="quoteForm-tipBlock"
                                resources={{ tooltipOpenIconUrl, tooltipCloseIconUrl }}
                            >
                                {" "}
                                Nicotine products include any of the following: cigarettes, cigars, electronic cigarettes,
                                chewing tobacco, nicotine gum, nicotine patches, pipes, or hookah.
                            </Tooltip>{" "}
                        </legend>
                        <div>
                            {state.fields[FIELDS.NICOTINE_USE] && (
                                <select name="nicotineUse" id="nicotineUse" style={{ display: "none" }}>
                                    <option
                                        value={usedNicotineRecently(state.fields[FIELDS.NICOTINE_USE]) ? "Yes" : "No"}
                                        name={state.fields[FIELDS.NICOTINE_USE]}
                                    >
                                        test
                                    </option>
                                </select>
                            )}

                            <select
                                name="nicotineUseProxy"
                                id="nicotineUseProxy"
                                required
                                className={[validOrInvalid(FIELDS.NICOTINE_USE), pristineOrDirty(FIELDS.NICOTINE_USE)].join(
                                    " ",
                                )}
                                value={state.fields[FIELDS.NICOTINE_USE]}
                                onChange={(e) =>
                                    dispatch({
                                        type: CHANGED_FIELD_VALUE,
                                        fieldName: FIELDS.NICOTINE_USE,
                                        value: e.target.value || undefined,
                                    })
                                }
                            >
                                <option value="" hidden>
                                    Select usage
                                </option>
                                <option value="nicotineUse-Never">Never</option>
                                <option value="nicotineUse-MoreThan60MonthsAgo">60 Months Ago or More</option>
                                <option value="nicotineUse-WithinPast36To59Months">36 to 59 Months Ago</option>
                                <option value="nicotineUse-WithinPast12To35Months">12 to 35 Months Ago</option>
                                <option value="nicotineUse-LessThan12MonthsAgo">Less than 12 Months Ago</option>
                            </select>
                        </div>
                    </fieldset>
                </div>
                {/*PANEL 2 RATE YOUR HEALTH VISIBLE STARTS*/}
                <div id="list" className={["quoteForm-rateYourHealth", "row", hideRateYourHealth ? "hide" : ""].join(" ")}>
                    <div className="small-6 columns">
                        <div className="quoteForm-rateYourHealthWrap">
                            <label className="quoteForm-rateYourHealth-label" htmlFor="rateYourHealth">
                                Rate Your Health
                            </label>{" "}
                            <span className="tooltip-wrap">
                                <a>
                                    <svg
                                        className="icon icon-arrow-questionMark"
                                        onClick={(e) => openModalEffect(e.currentTarget.parentElement, modalPopupContent)}
                                    >
                                        <use xlinkHref={tooltipOpenIconUrl} />
                                    </svg>
                                </a>
                            </span>
                        </div>
                        <select
                            name="rateYourHealth"
                            id="rateYourHealth"
                            className={[
                                "quoteForm-rateHealth",
                                validOrInvalid(FIELDS.HEALTH_RATING),
                                pristineOrDirty(FIELDS.HEALTH_RATING),
                            ].join(" ")}
                            value={state.fields[FIELDS.HEALTH_RATING] || ""}
                            onChange={(e) =>
                                dispatch({
                                    type: CHANGED_FIELD_VALUE,
                                    fieldName: FIELDS.HEALTH_RATING,
                                    value: e.target.value || undefined,
                                })
                            }
                        >
                            <option value="" hidden>
                                Select
                            </option>
                            {state.healthConditionGroup &&
                                state.healthConditionGroup.healthRatings.map((healthRating) => (
                                    <option value={healthRating} key={healthRating}>
                                        {HEALTH_RATING_TEXT_CONTENTS[healthRating]}
                                    </option>
                                ))}
                        </select>
                    </div>
                </div>
                {/*PANEL 2 RATE YOUR HEALTH VISIBLE ENDS*/}
                <div className="row quoteForm-amountInfo">
                    <div className="small-6 columns">
                        <label htmlFor="coverageAmount">Coverage Amount</label>
                        <select
                            name="coverageAmount"
                            id="coverageAmount"
                            className={[validOrInvalid(FIELDS.COVERAGE_AMOUNT), pristineOrDirty(FIELDS.COVERAGE_AMOUNT)].join(
                                " ",
                            )}
                            value={state.fields[FIELDS.COVERAGE_AMOUNT] || ""}
                            onChange={(e) =>
                                dispatch({
                                    type: CHANGED_FIELD_VALUE,
                                    fieldName: FIELDS.COVERAGE_AMOUNT,
                                    value: e.target.value,
                                })
                            }
                        >
                            {productType === "express_term" && (
                                <option value="" hidden>
                                    Select
                                </option>
                            )}
                            {getCoverageAmounts(state.productType).map((amount) => (
                                <option value={amount} key={amount}>
                                    ${amount}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="small-6 columns">
                        <label htmlFor="termLength">Term Length</label>
                        <select
                            name="termLength"
                            id="termLength"
                            className={[validOrInvalid(FIELDS.TERM_LENGTH), pristineOrDirty(FIELDS.TERM_LENGTH)].join(" ")}
                            value={state.fields[FIELDS.TERM_LENGTH] || ""}
                            onChange={(e) =>
                                dispatch({
                                    type: CHANGED_FIELD_VALUE,
                                    fieldName: FIELDS.TERM_LENGTH,
                                    value: e.target.value,
                                })
                            }
                        >
                            <option value="" hidden>
                                Select
                            </option>
                            {(state.age
                                ? getTermLengthOptions(
                                      state.age,
                                      state.productType,
                                      usedNicotineRecently(state.fields.nicotine),
                                  )
                                : []
                            ).map((termLength) => (
                                <option value={termLength} key={termLength}>
                                    {termLength} Years
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div className="buttonWrap">
                <button
                    type="button"
                    id="panel3-back"
                    className="button panel-container-backbtn"
                    style={{ paddingRight: "0" }}
                    onClick={(e) => onBack(state.fields)}
                >
                    Back
                    <svg className="icon panel-container-icon-arrow-left">
                        <use xlinkHref={leftArrowSvgIconUrl} />
                    </svg>
                </button>
                {state.productType === PRODUCT_TYPES.EXPRESS_TERM ? (
                    <button
                        id="seeQuote"
                        type="button"
                        data-itc
                        className="button btn-pink panel-container-seeMyQuote "
                        onClick={(e) => {
                            dispatch({ type: "NAVIGATE_NEXT", now: Date.now() });
                        }}
                    >
                        See Your Quote
                    </button>
                ) : (
                    <button
                        id="panel3-next"
                        type="button"
                        data-itc="AL31B"
                        data-load-page="/restservices/aggregate"
                        data-special-action="sf-p"
                        className="button btn-pink panel-container-nextbtn"
                        onClick={(e) => {
                            dispatch({ type: "NAVIGATE_NEXT", now: Date.now() });
                        }}
                    >
                        Next
                    </button>
                )}
            </div>
        </div>
    );
}
